const responsiveObj = {
  desktop: {
    breakpoint: { max: 6000, min: 991 },
    items: 3,
    partialVisibilityGutter: 20,
  },
  tablet: {
    breakpoint: { max: 990, min: 768 },
    items: 3,
    partialVisibilityGutter: 10,
  },
  mobile: {
    breakpoint: { max: 767, min: 0 },
    items: 1,
    partialVisibilityGutter: 6,
  },
};

export default responsiveObj;
