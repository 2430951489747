import React, { FC } from "react"
import { graphql } from "gatsby"

import { Container } from "react-bootstrap"
import DangerouslySetInnerHtml from "gatsby-theme-nurofen/src/components/common/DangerouslySetInnerHtml"
import PromoImage from "gatsby-theme-nurofen/src/components/PromoBanner/PromoImage"
import PromoItem from "gatsby-theme-nurofen/src/components/PromoBanner/PromoItem"
import { IPromoBannerProps } from "gatsby-theme-nurofen/src/components/PromoBanner/model"

import "./PromoBanner.scss"

const PromoBanner: FC<IPromoBannerProps> = ({
  title,
  btn,
  promoItems,
  promoImg,
  promoImgAlt,
  bgColor,
  promoBannerAnchor,
  promoAdditionalInfo,
}) => (
  <div
    className="campaign-anchor-section"
    {...(promoBannerAnchor ? { id: promoBannerAnchor } : {})}
  >
    <div
      className={`promo-product-banner promo-product-banner--${bgColor}`}
      style={
        {
          backgroundColor: `var(--${bgColor})`,
        } as React.CSSProperties
      }
    >
      <Container fluid>
        <DangerouslySetInnerHtml
          className="promo-product-banner__title"
          element="strong"
          html={title}
        />
        <div className="promo-product-banner__frame">
          <PromoImage
            img={promoImg}
            alt={promoImgAlt}
            btn={{ variant: bgColor === "gold" ? "light" : "primary", ...btn }}
          />
          <ul className="promo-product-banner__list">
            {promoItems.map(
              ({
                properties: {
                  promoBannerCardTitle,
                  promoBannerCardImage,
                  promoBannerCardImageAlt,
                  promoBannerCardDescription,
                  promoBannerCardDisclaimer,
                },
              }) => (
                <PromoItem
                  key={promoBannerCardTitle}
                  title={promoBannerCardTitle}
                  img={promoBannerCardImage}
                  alt={promoBannerCardImageAlt}
                  description={promoBannerCardDescription}
                  disclaimer={promoBannerCardDisclaimer}
                />
              )
            )}
          </ul>
          {promoAdditionalInfo ? (
            <DangerouslySetInnerHtml
              className="promo-product-banner__additional-info"
              html={promoAdditionalInfo}
            />
          ) : null}
        </div>
      </Container>
    </div>
  </div>
)

export default PromoBanner

export const query = graphql`
  fragment FragmentPromoBannerAU on IPromoBanner {
    promoBannerTitle
    promoBannerImage {
      fallbackUrl
      url
      gatsbyImage {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mobileImage: gatsbyImage {
        childImageSharp {
          fluid(maxWidth: 280) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      svg {
        content
      }
    }
    promoBannerImageAlt
    promoBannerButton {
      properties {
        ...FragmentNFButton
      }
    }
    promoBannerCards {
      properties {
        promoBannerCardImage {
          fallbackUrl
          url
          gatsbyImage {
            childImageSharp {
              fluid(maxWidth: 75) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          svg {
            content
          }
        }
        promoBannerCardImageAlt
        promoBannerCardTitle
        promoBannerCardDescription
        promoBannerCardDisclaimer
      }
    }
    promoBannerBgColor {
      label
    }
    promoBannerAnchor
    promoAdditionalInfo
  }
`
